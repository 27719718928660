var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 font-Roboto shared-account-index px-4",attrs:{"min-height":"100"}},[_c('div',{staticClass:"white rounded-sm elevation-1 px-4 pt-6 pb-7"},[_c('validation-observer',{ref:'observer',scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"lazy-validation":"","autocomplete":"off"}},[_c('v-card-text',{staticClass:"pb-5 px-6 white-inputs"},_vm._l((_vm.fieldsTop),function(field){return _c('v-row',{key:field.name},[(field.type == 'text')?[_c('v-col',{staticClass:"pa-0 mb-2",class:field.class ? field.class : '',attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"font-16px text-777"},[_vm._v(" "+_vm._s(field.label)+" ")]),_c('v-text-field',{attrs:{"type":field.inputType,"placeholder":field.placeholder,"solo":"","background-color":"#EAEDF0","error-messages":errors,"hide-details":errors.length <= 0,"autocomplete":"chrome-off","flat":"","disabled":_vm.getShopAuthUser[0].user_role == 'S' || field.disabled
                        ? true
                        : false},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),(field.post_label)?_c('span',{staticClass:"post-label"},[_vm._v(_vm._s(field.post_label))]):_vm._e(),_c('div',{staticClass:"v-messages theme--light error--text"},[_vm._v(" "+_vm._s(errors ? errors[0] : "")+" ")])]}}],null,true)})],1)]:_vm._e(),(field.togglePassword)?[_c('v-col',{staticClass:"pa-0 mb-3",attrs:{"col":"12"}},[(_vm.getShopAuthUser[0].user_role != 'S')?_c('h6',{staticClass:"cursor-pointer primary--text font-16px",class:_vm.showPasswordFields ? 'mb-6' : 'mb-0',on:{"click":function($event){return _vm.togglePassword()}}},[_vm._v(" パスワード変更 ")]):_vm._e(),(_vm.showPasswordFields)?_c('v-row',_vm._l((field.fields),function(fld){return _c('v-col',{key:fld.label,staticClass:"py-0 mb-2",class:fld.class ? fld.class : '',attrs:{"cols":"12","md":fld.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":fld.rules,"name":fld.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{staticClass:"font-16px text-777"},[_vm._v(" "+_vm._s(fld.label)+" ")]),_c('v-text-field',{attrs:{"type":fld.inputType,"placeholder":fld.placeholder,"solo":"","background-color":"#EAEDF0","error-messages":errors,"hide-details":errors.length <= 0,"autocomplete":"chrome-off","flat":""},model:{value:(fld.value),callback:function ($$v) {_vm.$set(fld, "value", $$v)},expression:"fld.value"}}),(fld.post_label)?_c('span',{staticClass:"post-label"},[_vm._v(_vm._s(fld.post_label))]):_vm._e(),_c('div',{staticClass:"v-messages theme--light error--text"},[_vm._v(" "+_vm._s(errors ? errors[0] : "")+" ")])]}}],null,true)})],1)}),1):_vm._e()],1)]:_vm._e()],2)}),1),_c('h6',{staticClass:"text-777 font-14px mb-2"},[_vm._v("メンバー")]),_c('v-card-text',{staticClass:"pb-6 px-6 card-border rounded-5"},_vm._l((_vm.fields),function(field){return _c('v-row',{key:field.name},[(field.type == 'text')?[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('div',{staticClass:"shared-inline-inputs position-relative"},[_c('label',{staticClass:"position-absolute font-16px fw-800 text-333"},[_vm._v(" "+_vm._s(field.label)+" ")]),_c('v-text-field',{attrs:{"type":field.inputType,"placeholder":field.placeholder,"solo":"","background-color":"#EAEDF0","error-messages":errors,"hide-details":errors.length <= 0,"autocomplete":"chrome-off","flat":"","disabled":_vm.getShopAuthUser[0].user_role == 'S' ? true : false},on:{"keyup":function($event){return _vm.addNewField()}},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)]}}],null,true)})],1)]:_vm._e()],2)}),1),(_vm.getShopAuthUser[0].user_role != 'S')?_c('div',{staticClass:"mt-7 d-flex justify-space-between align-center"},[_c('v-btn',{attrs:{"min-width":"146px","color":"white border-primary primary--text font-14px"},on:{"click":_vm.cancelPage}},[_vm._v(" キャンセル ")]),_c('v-btn',{attrs:{"type":"button","min-width":"146px","disabled":invalid,"loading":_vm.loading,"color":"primary white--text font-14px"},on:{"click":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._v(" 保存する ")])],1):_vm._e()],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container
    min-height="100"
    class="px-0 font-Roboto shared-account-index px-4"
  >
    <div class="white rounded-sm elevation-1 px-4 pt-6 pb-7">
      <validation-observer :ref="'observer'" v-slot="{ invalid }">
        <v-form lazy-validation autocomplete="off">
          <!-- Step 1 -->
          <v-card-text class="pb-5 px-6 white-inputs">
            <v-row v-for="field in fieldsTop" :key="field.name">
              <template v-if="field.type == 'text'">
                <v-col
                  cols="12"
                  class="pa-0 mb-2"
                  :md="field.additional_field ? '6' : '12'"
                  :class="field.class ? field.class : ''"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <label class="font-16px text-777">
                      {{ field.label }}
                    </label>
                    <v-text-field
                      :type="field.inputType"
                      :placeholder="field.placeholder"
                      solo
                      background-color="#EAEDF0"
                      v-model="field.value"
                      :error-messages="errors"
                      :hide-details="errors.length <= 0"
                      autocomplete="chrome-off"
                      flat
                      :disabled="
                        getShopAuthUser[0].user_role == 'S' || field.disabled
                          ? true
                          : false
                      "
                    >
                    </v-text-field>
                    <span class="post-label" v-if="field.post_label">{{
                        field.post_label
                      }}</span>
                      <div class="v-messages theme--light error--text">
                        {{ errors ? errors[0] : "" }}
                      </div>
                  </validation-provider>
                </v-col>
              </template>
              <template v-if="field.togglePassword">
                <v-col class="pa-0 mb-3" col="12">
                  <h6
                    class="cursor-pointer primary--text font-16px"
                    :class="showPasswordFields ? 'mb-6' : 'mb-0'"
                    @click="togglePassword()"
                    v-if="getShopAuthUser[0].user_role != 'S'"
                  >
                    パスワード変更
                  </h6>
                  <v-row v-if="showPasswordFields">
                    <v-col
                      v-for="fld in field.fields"
                      :key="fld.label"
                      cols="12"
                      class="py-0 mb-2"
                      :md="fld.additional_field ? '6' : '12'"
                      :class="fld.class ? fld.class : ''"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="fld.rules"
                        :name="fld.name"
                      >
                        <label class="font-16px text-777">
                          {{ fld.label }}
                        </label>
                        <v-text-field
                          :type="fld.inputType"
                          :placeholder="fld.placeholder"
                          solo
                          background-color="#EAEDF0"
                          v-model="fld.value"
                          :error-messages="errors"
                          :hide-details="errors.length <= 0"
                          autocomplete="chrome-off"
                          flat
                        >
                        </v-text-field>
                        <span class="post-label" v-if="fld.post_label">{{
                          fld.post_label
                        }}</span>
                        <div class="v-messages theme--light error--text">
                          {{ errors ? errors[0] : "" }}
                        </div>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
            </v-row>
          </v-card-text>

          <h6 class="text-777 font-14px mb-2">メンバー</h6>
          <v-card-text class="pb-6 px-6 card-border rounded-5">
            <v-row v-for="field in fields" :key="field.name">
              <template v-if="field.type == 'text'">
                <v-col
                  cols="12"
                  class="pa-0"
                  :md="field.additional_field ? '6' : '12'"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <div class="shared-inline-inputs position-relative">
                      <label
                        class="position-absolute font-16px fw-800 text-333"
                      >
                        {{ field.label }}
                      </label>
                      <v-text-field
                        :type="field.inputType"
                        :placeholder="field.placeholder"
                        @keyup="addNewField()"
                        solo
                        background-color="#EAEDF0"
                        v-model="field.value"
                        :error-messages="errors"
                        :hide-details="errors.length <= 0"
                        autocomplete="chrome-off"
                        flat
                        :disabled="
                          getShopAuthUser[0].user_role == 'S' ? true : false
                        "
                      >
                      </v-text-field>
                    </div>
                  </validation-provider>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
          <div
            class="mt-7 d-flex justify-space-between align-center"
            v-if="getShopAuthUser[0].user_role != 'S'"
          >
            <v-btn
              min-width="146px"
              color="white border-primary primary--text font-14px"
              @click="cancelPage"
            >
              キャンセル
            </v-btn>
            <v-btn
              @click.prevent="submit"
              type="button"
              min-width="146px"
              :disabled="invalid"
              :loading="loading"
              color="primary white--text font-14px"
            >
              保存する
            </v-btn>
          </div>
        </v-form>
      </validation-observer>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SharedAccountStepIndex",
  // metaInfo: {
  //   title: "共有用アカウント"
  // },
  props: ["id"],
  data() {
    return {
      loading: false,
      errors: "",
      formStatus: false,
      showPasswordFields: false,
      fieldsTop: [
        {
          label: "ID",
          name: "s_account_id",
          type: "text",
          inputType: "text",
          placeholder: "",
          value: "",
          rules: "required|min:8|only_english_lang_allowed|dont_allow_sysmbol",
          post_label: "※「@」は使用できません。"
        },
        {
          label: "パスワード",
          name: "raw_password",
          type: "text",
          inputType: "text",
          placeholder: "",
          value: "",
          rules: "required|only_english_lang_allowed",
          disabled: true
        },
        {
          label: "パスワード変更",
          togglePassword: true,
          class: "input-slide",
          fields: [
            {
              label: "新しいパスワード",
              name: "password",
              type: "text",
              inputType: "text",
              placeholder: "",
              value: "",
              rules: "min:8|verify_password|only_english_lang_allowed",
              post_label: "※ 英大文字、英小文字、数字をすべて使用してください"
            },
            {
              label: "新しいパスワード(確認)",
              name: "password_confirmation",
              type: "text",
              inputType: "text",
              placeholder: "",
              value: "",
              rules: "password_confirmed:password"
            }
          ]
        }
      ],
      fields: [
        {
          label: "1.",
          name: "name_field_1",
          type: "text",
          placeholder: "メンバー１",
          value: "",
          rules: ""
        },
        {
          label: "2.",
          name: "name_field_2",
          type: "text",
          placeholder: "メンバー2",
          value: "",
          rules: ""
        },
        {
          label: "3.",
          name: "name_field_3",
          type: "text",
          placeholder: "メンバー3",
          value: "",
          rules: ""
        },
        {
          label: "4.",
          name: "name_field_4",
          type: "text",
          placeholder: "メンバー4",
          value: "",
          rules: ""
        },
        {
          label: "5.",
          name: "name_field_5",
          type: "text",
          placeholder: "メンバー5",
          value: "",
          rules: ""
        }
      ]
    };
  },
  created() {
    this.assignShopSharedAccountData();
    // let this_this = this;
    // this.$root.$off("shopChanged");
    // this.$root.$on("shopChanged", function() {
    //   this_this.assignShopSharedAccountData();
    // });
  },
  computed: {
    ...mapGetters(["getShopUserById", "getShopAuthUser"]),
    lastIncrementField() {
      return this.fields[this.fields.length - 1];
    },
    secondlastIncrementField() {
      return this.fields[this.fields.length - 2];
    }
  },
  methods: {
    assignShopSharedAccountData() {
      let userData = {
        shop_id: this.$route.query.shop, //Shop Id
        user_id: this.id
      };

      this.$store.dispatch("SHOP_USER_BY_USERID", userData).finally(() => {
        this.loading = false;
        const activeShop = this.getShopUserById[0]["user"];
        this.fieldsTop.forEach(field => {
          if (!field.togglePassword) {
            field.value = activeShop?.[field.name];
          }
        });

        this.clearFieldValues();

        if (activeShop?.shared_accounts && activeShop.shared_accounts.length) {
          activeShop.shared_accounts.forEach((field, index) => {
            if (!this.fields[index]) {
              this.addNewField();
            }
            this.fields[index].value = field.name;
            this.addNewField();
          });
        }
      });

      //const activeShop = this.$store.getters.getFirstShopObject;
      // this.fieldsTop.forEach(field => {
      //   if (!field.togglePassword) {
      //     field.value = activeShop?.shared_account?.[field.name];
      //   }
      // });

      // if (
      //   activeShop?.shared_account?.shared_accounts &&
      //   activeShop.shared_account.shared_accounts.length
      // ) {
      //   activeShop.shared_accounts.forEach((field, index) => {
      //     if (!this.fields[index]) {
      //       this.addNewField();
      //     }
      //     this.fields[index].value = field.name;
      //   });
      // }
    },
    clearFieldValues() {
      this.fields.filter((field, index) => {
        return index <= 4;
      });

      this.fields.forEach((field, index) => {
        field.value = "";
        if (index > 4) {
          this.fields.splice(index);
        }
      });
    },
    addNewField() {
      let fieldsLength = this.fields.length;

      if (this.lastIncrementField.value != "") {
        this.fields.push({
          label: `${fieldsLength + 1}.`,
          name: `input_${fieldsLength + 1}`,
          type: "text",
          placeholder: `メンバー${fieldsLength + 1}`,
          value: "",
          rules: "",
          newAdded: true
        });
      } else if (
        (!this.lastIncrementField.newAdded ||
          this.secondlastIncrementField.value == "") &&
        fieldsLength > 5
      ) {
        let lastIndex = this.fields.indexOf(this.lastIncrementField);
        this.fields.splice(lastIndex, 1);
      }
    },
    togglePassword() {
      this.showPasswordFields = !this.showPasswordFields;
    },
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        this.loading = true;
        let nameArray = [];
        let data = {};
        //data.shop_id = this.$store.getters.getFirstShopObject.id;
        data.shop_id = this.$route.query.shop;
        data.user_id = this.id;
        this.fieldsTop.forEach(field => {
          if (field?.value) {
            if (field.name == "raw_password") {
              data.password = field.value;
            } else {
              data[field.name] = field.value;
            }
          }

          if (field.fields) {
            field.fields.forEach(newfield => {
              if (newfield?.value) {
                data.new_password = newfield.value;
              }
            });
          }
        });

        this.fields.forEach(field => {
          if (field?.value) {
            nameArray.push(field.value);
          }
        });
        data.names = nameArray;

        this.$store
          .dispatch("SHOP_UPDATE_SHARED_ACCOUNT", data)
          .then(() => {
            this.$store.commit("showAlert", {
              text: "編集内容を保存しました。",
              successStatus: "info"
            });
          })
          .catch(error => {
            this.steps.forEach((value, key) => {
              if (error?.data?.errors)
                this.$refs.observer.setErrors(error?.data?.errors);
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    cancelPage() {
      this.$router.push({
        name: "StoreDetails",
        params: { id: this.$route.query.shop }
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
